<template>
    <div class="col-sm-12" id="politicaDePrivacidade">
        <br>
        <br>
        <br>
        <br>
        <br>
        <h2 class="destaque">TERMO DE USO E CONSENTIMENTO</h2>
        <div class="col-sm-12 paragrafo">
            <!-- <h4 class="destaque">INTRODUÇÃO</h4> -->
            <p>
                A <b class="destaque">EAGLE TRACK</b> é a empresa fabricante, desenvolvedora e detentora dos direitos 
                autorais e propriedade intelectual atinente ao software EAGLE TRACK, o qual via web, tablet, smartphone
                e outros aparelhos com acesso à internet ou ao aplicativo especial, possibilita por meio de autogestão, 
                o gerenciamento veicular, pessoal ou de objetos.  
            </p>
            <p>
                Nossos  termos  são
                importantes  e  afetam  seus  direitos  legais,  além  de  regularem  a  utilização  da plataforma.  
                <b class="destaque">
                    É fundamental  que  você  leia  e  entenda  este  e  outros  documentos  como,  
                    por  exemplo, nossa Política de Privacidade.
                </b>
            </p>
            <p>
                Esperamos  que  você  compreenda  todos  os  termos  e  tenha  uma  ótima 
                experiência  em  nossa  plataforma e  com  os nossos serviços: 
            </p>
            <p class="destaque">
                Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o Titular concorda
                com o tratamento  de  seus  dados  pessoais,  em  conformidade  com  a  Lei  nº  13.709 – Lei  Geral  
                de  Proteção  de  Dados Pessoais (LGPD).
            </p>
            <p>
                Ressaltamos que o cliente é livre para concordar ou não com nossas políticas,
                <b class="destaque">sendo possível optar pelo não compartilhamento dos seus dados.</b>
                Se você não concorda com os nossos Termos , por favor, não use este serviço.
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">DADOS PESSOAIS</h4>
            <p>
                O Titular  autoriza  a Controladora  a realizar o 
                tratamentodos dados pessoais fornecidos para fins de  contato, oferta e venda de produtos, 
                promoções, localização e principalmente, a adequada prestação de serviços disponibilizados.
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">COMPARTILHAMENTO DE DADOS</h4>
            <p>
                A  Controladora  fica  autorizada  a  compartilhar  os  dados  pessoais  do  
                Titular  com  outros  agentes  de  tratamento  de dados,  caso  seja  
                necessário  para  as  finalidades  listadas  neste  instrumento,  desde  que,  sejam  
                respeitados  os  princípios da boa-fé, finalidade, adequação, necessidade, livre acesso, 
                qualidade dos dados, transparência, segurança, prevenção, não discriminação e responsabilização e 
                prestação de contas.
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <h4 class="destaque">RESPONSABILIDADE</h4>
            <p>
                A  Controladora  se  responsabiliza  por  manter  medidas  de  segurança,  técnicas  e  administrativas
                suficientes  a  proteger os  dados  pessoais  do  Titular  e  à  Autoridade  Nacional  de  Proteção  de
                Dados  (ANPD),  comunicando  ao  Titular,  caso ocorra  algum  incidente  de  segurança  que  possa  
                acarretar  risco  ou  dano  relevante,  conforme  artigo  48  da  Lei  n° 13.709/2020.
            </p>
            <p>
                Dessa forma, a <b class="destaque"> EAGLE TRACK </b> declara:
            </p>
            <p>
                1) Estar ciente de suas obrigações e de acordo com a Lei n. 13.709/2018 
                (Lei Geral de Proteção de Dados).
                <br>
                2) Realizar  o  tratamento  dos  dados  pessoais  observando  os  princípios  de  boa-fé,  da  
                necessidade,  da  finalidade, do livre acesso, da transparência, da segurança e prevenção dos dados.
                <br>
                3) Responder por qualquer tratamento inadequado ou por eventuais vazamentos ocasionados por sua culpa 
                ou dolo, sendo cabível direito de regresso pela para lesada em casos de penalidades, inclusive as 
                aplicadas pela Autoridade Nacional de Proteção de Dados.
                <br>
                4) Não utilizar dados  pessoais  para  qualquer  outra  finalidade  que  não  seja  o  cumprimento 
                das  obrigações evidenciadas em suas políticas, e com o intuito de ofertar para o 
                <b class="destaque">CLIENTE/TITULAR</b> a adequada prestação dos  serviços  dos  produtos 
                disponibilizados,  não  processando,  transferindo, divulgando, transmitindo ou disseminando dados
                pessoais, sem a aprovação prévia do <b class="destaque">CLIENTE/TITULAR</b>.
            </p>
        </div>
        <div class="col-sm-12 paragrafo">
            <p class="text-justify">
                Este  consentimento pode  ser  canceladoa  qualquer  momentomediante  solicitação. Para  esclarecer
                quaisquer dúvidas entre em contato com o Encarregado de Proteção de Dados Pessoais, pelo e-mail: 
                <b class="destaque">dpo@eagletrack.com.br.</b>
            </p>
        </div>
    </div>
</template>
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-100341043-1"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());  gtag('config', 'UA-100341043-1');
</script>
<script lang="js">
import Vue from 'vue'
export default Vue.extend({
    
})
</script>
<style lang="scss">
    #politicaDePrivacidade{
        padding-left: 5vw;
        padding-right: 5vw;
        font-family: nexabook;
        .paragrafo{
            text-align: justify;
        }
        .destaque{
            font-family: nexabold;
        }
    }
</style>